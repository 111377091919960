<template>
   <v-dialog v-model="show" width="550" persistent>
      <v-card>
         <v-card-text class="text--primary" style="padding-bottom: 0px">
            <v-layout align-start>
               <v-flex xs12>
                  <v-card elevation="0">
                     <v-container fluid>
                        <v-layout wrap>
                           <v-flex xs12 py-4>
                              <span class="text-h5 font-weight-medium">
                                 Información de alumno
                              </span>
                           </v-flex>
                           <v-flex xs12>
                              <v-simple-table>
                                 <template #default>
                                    <tbody>
                                       <tr>
                                          <td class="simple-title">Carnet</td>
                                          <td>{{ datos.alumnoCarnet }}</td>
                                       </tr>
                                       <tr>
                                          <td class="simple-title">Nombre</td>
                                          <td>{{ datos.nombre }}</td>
                                       </tr>
                                       <tr>
                                          <td class="simple-title">DUI</td>
                                          <td>
                                             {{
                                                datos.dui == ''
                                                   ? 'No registrado'
                                                   : datos.dui
                                             }}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td class="simple-title">Email</td>
                                          <td>
                                             {{
                                                datos.email == null
                                                   ? 'No registrado'
                                                   : datos.email
                                             }}
                                          </td>
                                       </tr>
                                       <tr>
                                          <td class="simple-title">Teléfono</td>
                                          <td>
                                             {{
                                                datos.telefono == null
                                                   ? 'No registrado'
                                                   : datos.telefono.substr(
                                                        0,
                                                        4
                                                     ) +
                                                     '-' +
                                                     datos.telefono.substr(4, 4)
                                             }}
                                          </td>
                                       </tr>

                                       <tr>
                                          <td class="simple-title">
                                             Es alumno externo
                                          </td>
                                          <td>
                                             {{
                                                datos.clienteExterno
                                                   ? 'Si'
                                                   : 'No'
                                             }}
                                          </td>
                                       </tr>
                                    </tbody>
                                 </template>
                              </v-simple-table>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-card-text>
         <v-card-actions style="padding: 8px 24px 24px">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click.stop="close">Salir</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import formatters from '../../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   name: 'DialogInfoPagos',
   directives: {
      mask
   },
   mixins: [formatters],
   props: {
      datos: {
         type: Object,
         default: null
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      detallesConfig: []
   }),
   computed: {
      show: {
         get() {
            return this.value
         },
         set(value) {
            var me = this
            me.$emit('input', value)
         }
      }
   },
   watch: {},
   methods: {
      close() {
         this.show = false
      }
   }
}
</script>

<style scoped>
.simple-title {
   font-weight: bold;
}
</style>
